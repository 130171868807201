import _ from 'lodash'
import Vue from 'vue'
import { watch } from '@vue/composition-api'

export const settingsObject = () => ({
  currentPage: 1,
  total: 1,
  to: 1,
  from: 1,
  perPage: 0,
  lastPage: 1,
})

export const setFilterFn = state => (newFilters, clean = false) => {
  let beforeFilters = _.cloneDeep(state.defaultFilters)

  if (!clean) {
    beforeFilters = _.cloneDeep(state.filters)
  }

  Vue.set(state, 'filters', _.merge(beforeFilters, newFilters))
}

export const setDefaultFilterFn = state => (newFilters, clean = false) => {
  let beforeFilters = _.cloneDeep(state.defaultFilters)

  if (!clean) {
    beforeFilters = _.cloneDeep(state.defaultFilters)
  }

  Vue.set(state, 'defaultFilters', _.merge(beforeFilters, newFilters))
}

export const watchFilter = state => {
  watch(
    () => state.filters,
    () => state.loadCategories(),
    { deep: true },
  )
  watch(
    () => state.defaultFilters,
    () => state.setFilter({}, true),
    { deep: true },
  )
}

export const setStateSettings = (state, responseData) => {
  state.settings.currentPage = responseData.current_page
  state.settings.total = responseData.total
  state.settings.to = responseData.to
  state.settings.from = responseData.from
  state.settings.perPage = responseData.per_page
  state.settings.lastPage = responseData.last_page
}

export default { settingsObject }

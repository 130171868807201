import { reactive, ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import store from '@/store'
import _ from 'lodash'
// import Vue from 'vue'
import {
  settingsObject, setFilterFn, watchFilter, setStateSettings, setDefaultFilterFn,
} from '@/helpers/filter'

export const categorySetup = () => {
  const state = reactive({
    categoryData: null,
    rootCategories: null,
    loadCategory: null,
  })

  const loading = ref(true)

  state.loadCategory = categoryId => {
    loading.value = true
    axiosIns({
      method: 'GET',
      url: `/categories/show/${categoryId}`,
    })
      .then(response => {
        state.categoryData = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => {
        store.commit('app/UPDATE_CURRENT_PAGE_TITLE', `${state.categoryData.name}`)
        loading.value = false
      })
  }
  state.loadRootCategories = () => {
    loading.value = true

    axiosIns({
      method: 'GET',
      url: '/categories/getAllRoot',
    })
      .then(response => {
        const defaultCategory = [{ id: null, name: '' }]
        state.rootCategories = _.merge(defaultCategory, response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  return { ...state, loading }
}

export const categoriesSetup = () => {
  const state = reactive({
    // categories: null,
    columns: [
      {
        label: 'Название',
        field: 'name',
      },
      {
        label: 'Офферы',
        field: 'v_offers',
      },
      {
        label: 'Менеджер',
        field: 'v_offers',
      },
      {
        label: 'Действия',
        field: 'action',
      },
    ],
    rows: [],
    settings: settingsObject(),
    filters: {},
    defaultFilters: {},

    // Methods
    loadCategories: null,
  })
  const loading = ref(true)

  state.loadCategories = (defaultFilters = null) => {
    if (defaultFilters !== null) {
      state.defaultFilters = defaultFilters
    }

    // Vue.set(state, 'loading', 0)
    loading.value = true

    axiosIns({
      method: 'GET',
      url: '/categories',
      params: _.merge(defaultFilters, state.filters),
    })
      .then(response => {
        const responseData = response.data.data

        setStateSettings(state, responseData)

        state.rows.splice(0, state.rows.length)
        state.rows.push(...response.data.data.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loading.value = false
        // Vue.set(state, 'loading', true)
      })
  }
  state.setFilter = setFilterFn(state)
  state.setDefaultFilter = setDefaultFilterFn(state)

  watchFilter(state)

  return { ...state, loading }
}

export default {
  categorySetup,
  categoriesSetup,
}

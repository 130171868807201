import _ from 'lodash'
import Vue from 'vue'

export default {
  data() {
    return {
      filter: {},
      defaultFilter: {
        perPage: 20,
        page: 1,
      },
    }
  },
  methods: {
    setDefaultFilter(filterObject) {
      Vue.set(this, 'defaultFilter', _.merge(filterObject, this.defaultFilter))
    },
    setFilterToDefault(filterObject) {
      Vue.set(this, 'filter', _.cloneDeep(filterObject))
    },
    doUpdateFilter(filterObject) {
      this.state.setFilter(filterObject)
    },
    doApplyFilters() {
      this.state.setFilter(_.cloneDeep(this.filter))
    },
    doResetFilters() {
      // const defaultFilters = _.cloneDeep(this.defaultFilter)
      this.setFilterToDefault(this.defaultFilter)
      // Vue.set(this, 'filter', defaultFilters)
      this.state.setFilter({ }, true)
    },
  },
  mounted() {
    this.setFilterToDefault(this.defaultFilter)
    this.state.setDefaultFilter(this.defaultFilter)
  },
  watch: {
    'state.filters': {
      deep: true,
      handler() {
        this.state.loadCategories()
      },
    },
  },
}

<template>
  <b-card>
    <div
      v-if="state.loading.value"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <data-table
      v-else
      :columns="state.columns"
      :rows.sync="state.rows"
      :settings="state.settings"
      :dev-logs="false"
      @updateFilter="doUpdateFilter"
    >
      <template v-slot:header-button>
        <b-dropdown
          id="dropdown-form1"
          ref="dropdown"
          class="mr-1"
          right
          variant="warning"
          text="Действия"
        >
          <b-dropdown-item>New around here? Sign up</b-dropdown-item>
          <b-dropdown-item>Forgot Password?</b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form class="py-1">
            <b-form-group
              label="По названию"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-name"
                v-model="filter.name"
                size="sm"
              />
            </b-form-group>
            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filter.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'categories-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>

      </template>

      <template v-slot:table-row="item">
        <span v-if="item.column.field === 'name' && $route.name === 'categories'">
          <router-link :to="{name: 'categories-parent', params: {id: item.row.id}}">
            {{ item.formattedRow[item.column.field] }}
          </router-link>
        </span>
        <span
          v-else-if="item.column.field === 'action'"
          class="float-right"
        >
          <router-link :to="{name: 'categories-update', params: {id: item.row.id}}">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
          </router-link>
        </span>
        <span
          v-else
          v-html="item.formattedRow[item.column.field]"
        />
      </template>
    </data-table>
  </b-card>
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import {
  BFormGroup, BCard, BDropdown, BDropdownForm, BButton, BDropdownItem, BButtonGroup, BFormSelect, BSpinner, BFormInput,
} from 'bootstrap-vue'
import filter from '@/mixins/filter'
// import Vue from 'vue'
// import store from '@/store'
import store from '@/store'
import { categoriesSetup, categorySetup } from './index'

export default {
  components: {
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    BDropdownItem,
    DataTable,
    BButtonGroup,
    BFormSelect,
    BSpinner,
    BFormInput,
  },
  mixins: [filter],
  setup() {
    const state = categoriesSetup()
    return { state }
  },
  beforeMount() {
    if (this.$route.name === 'categories-parent') {
      const stateCategory = categorySetup()
      stateCategory.loadCategory(this.$route.params.id)

      this.setDefaultFilter({
        parent_id: this.$route.params.id,
      })
    }
    // this.setDefaultFilter({})
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
}
</script>
